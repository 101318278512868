import React from 'react';
import styled from 'styled-components'

const Img = styled.div`
  background-image: url(${props => props.img ? props.img : ''}); /**устанавливаем картинку на фон**/
  width: 1100px; /**ширина**/
  height: 400px; /**высота**/
  margin: auto; /**выравнивание блока по центру**/
  border-radius: 20px;
  background-size: cover;
  background-position: center;
  margin-bottom: 22px;
  margin-top: 173px;
  z-index: -1;
  @media (max-width: 1400px) {
    width: 900px;
  }
  @media (max-width: 1268px) {
    width: 700px;
  }
  @media (max-width: 968px) {
    width: 600px;
  }
  @media (max-width: 768px) {
    width: 400px;
  }
  @media (max-width: 568px) {
    width: 310px;
    height: 200px;
    margin-left: 10px;
    margin-top: 80px;
  }
  @media (max-width: 368px) {
    width: 290px;
    height: 190px;
  }

`;

const Div = styled.div`

  width: 1000px; /**ширина**/
  border-radius: 20px;
  margin: 100px auto 22px;
  @media (max-width: 1400px) {
    width: 900px;
  }
  @media (max-width: 1268px) {
    width: 700px;
  }
  @media (max-width: 968px) {
    width: 600px;
  }
  @media (max-width: 768px) {
    width: 400px;
  }
  @media (max-width: 568px) {
    width: 310px;
    margin-left: 0px;
    margin-top: 0px;
  }
  @media (max-width: 368px) {

  }

`;

const BackImg = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(0.34deg, #0B0A0A 9.39%, rgba(11, 10, 10, 0.76) 54.03%, rgba(8, 7, 7, 0.39) 99.61%);
`;

const ImgText = styled.div`
  padding-top: 228px;
  margin-left: -100px;
  font-size: 72px;
  line-height: 86px;
  color: #4285F4;
  max-width: 1000px;
  @media (max-width: 1400px) {
    font-size: 52px;
    line-height: 63px;
    max-width: 900px;
  }
  @media (max-width: 1268px) {
    font-size: 42px;
    line-height: 53px;
    max-width: 800px;
  }
  @media (max-width: 968px) {
    font-size: 38px;
    margin-left: -50px;
    max-width: 600px;
  }
  @media (max-width: 768px) {
    margin-left: -35px;
    max-width: 500px;
  }
  @media (max-width: 568px) {
    font-size: 36px;
    line-height: 43px;
    max-width: 316px;
    margin-left: -29px;
    padding-top: 100px
  }
  @media (max-width: 368px) {
    margin-left: 0px;
  }
`;

const Content = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  width: 1100px; /**ширина**/
  /**выравнивание блока по центру**/
  margin: auto;
  z-index: -1;
  color: #FFFFFF;
  @media (max-width: 1400px) {
    width: 900px;
  }
  @media (max-width: 1268px) {
    width: 700px;
  }
  @media (max-width: 968px) {
    width: 600px;
  }
  @media (max-width: 768px) {
    width: 400px;
  }
  @media (max-width: 568px) {
    font-size: 12px;
    line-height: 24px;
    max-width: 293px;
    margin-left: 30px;
    margin-top: 80px;
  }
  @media (max-width: 368px) {
    margin-left: 40px;
    max-width: 260px;
  }
`;

const Div2 = styled.div`
  margin-left: -100px;
  white-space: pre-wrap;
  @media (max-width: 900px) {
    font-size: 16px;
    margin-left: -50px;
  }
  @media (max-width: 700px) {
    margin-left: -35px;
    font-size: 12px;
    line-height: 24px;
    font-weight: 300;
  }
`;

const Title = styled.div`
  font-weight: 300;
  font-size: 72px;
  line-height: 96px;
  //margin-left: -60px;
  margin-top: 110px;
  /* identical to box height, or 133% */
  margin-left: -100px;
  text-transform: uppercase;

  color: #FFFFFF;
  @media (max-width: 900px) {
    font-size: 65px;
    margin-left: -50px;
  }
  @media (max-width: 700px) {
    font-size: 55px;
    margin-left: -35px;
  }
  @media (max-width: 568px) {
    font-size: 36px;
    line-height: 43px;
    margin-left: 0;
    margin-top: 0;
  }
`;

const Span = styled.span`
  font-weight: bold;
`;

const Component = styled.div`
  margin-bottom: 20px;
  margin-left: 100px;
  @media (max-width: 700px) {
    margin-bottom: 150px;
  }
  @media (max-width: 568px) {
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Container = styled.div`
  @media (max-width: 568px) {
    padding: 0 10px 0 10px;
    display: flex;
    flex-direction: column;
  }

`;


const Business = (props) => {

    return (
        <Container>
            <Div>
                <Title>Для <Span>бизнеса</Span> </Title>
            </Div>
            {props.DATA.map((item, index) => {
                return (
                    <Component id={"business" + item.id} key={index}>
                        <Img img={item.image.localFile.publicURL}>
                            <BackImg>
                                <ImgText>
                                    {item.title}
                                </ImgText>

                            </BackImg>
                        </Img>
                        <Content>
                            <Div2>
                                {item.description}
                            </Div2>

                        </Content>
                    </Component>
                );
            })}


        </Container>
    );
}

export default Business;
